<template>
  <div class="card full-height bg-ligth">
    <div class="card-body">
      <div class="row d-flex align-items-end">
        <div class="form-group col-md-3">
          <label for="selectFilterType">Tipo</label>
          <select id="selectFilterType" v-model="pesquisa.tipo" class="form-control">
            <option value="rg">RG</option>
            <option value="pis">Pis</option>
            <option value="nome_sobrenome">Nome</option>
          </select>
        </div>

        <div class="form-group col-md-3">
          <label for="text">Pesquisa</label>
          <input
              v-model="pesquisa.texto"
              type="text"
              class="form-control"
              id="text"
              placeholder
          />
        </div>
        <div class="form-group">
          <button class="btn btn-success" @click="pesquisar()">
            <i class="fa fa-search search-icon m-0 p-0"></i>
          </button>
        </div>
      </div>

          
                 <b-table
              :fields="['identificacao','nome_sobrenome','rg','cpf', 'acoes']"
              :items="lista_funcionarios"
              :per-page="perPage"
              :current-page="currentPage"
              id="funcionarios-table"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >
      
               <template #head(acoes)><span></span></template>
           <template #cell(acoes)="{item}">
              <div class="text-right w-100">
            
                      <button
                        @click="atualizar(item)"
                        class="btn btn-sm btn-icon btn-bg-primary btn-icon-light btn-hover-light"
                      >
                        <i class="flaticon-edit-1"></i>
                      </button>

                      <!-- <button
                        @click="confirm(lista)"
                        class="ml-1 btn btn-sm btn-icon btn-bg-danger btn-icon-light btn-hover-light"
                      >
                        <i class="flaticon-lock"></i>
                      </button> -->
                      <button
                        v-if="item.status == 1"
                        @click="toGo('controlePonto', item)"
                        class="ml-1 btn btn-sm btn-icon btn-bg-warning btn-icon-light btn-hover-light"
                      >
                        <i class="fas fa-arrow-circle-right"></i>
                      </button>

                      <div
                      v-if="dados_usu.perfil_id == 1"
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                     
                        <a class="dropdown-item" href="#"
                          >Autorizar biomentria</a
                        >
                      </div>

                      <b-dropdown
                        v-if="dados_usu.perfil_id == 1"
                        id="dropdown-left"
                        text="Selecione"
                        variant="info"
                        class="m-2"
                      >
                        <!-- <b-dropdown-item>Selecione</b-dropdown-item> -->
                        <b-dropdown-item @click="autorizar(item)"
                          >Autorizar</b-dropdown-item
                        >
                      </b-dropdown>
               
              </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_funcionarios.length"
              :per-page="perPage"
              aria-controls="funcionarios-table"
          >
          </b-pagination>
              <!-- </div> -->
        
          </div>
        </div>
 
    

</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast, {
  position: "top",
  duration: 3000,
});

export default {
  data() {
    return {
         pesquisa: {
        tipo: "nome",
        texto: "",
      },
        currentPage: 1,
      perPage: 10,
      // status: 1,
      verif: false,
    };
  },
  components: {
    // KTCodePreview
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Funcionarios" }]);
  },
  // created() {
  //   this.lista_matriculas();
  // },
  computed: {
    lista_funcionarios() {
      // return this.$store.state.configEmpresa.lista_funcionarios;

       return this.$store.state.configEmpresa.lista_funcionarios.filter(item => {
        if (!this.pesquisa.texto) return true
        return String(item[this.pesquisa.tipo]).toLowerCase().indexOf(this.pesquisa.texto.toLowerCase()) !== -1
      });
    },
    dados_usu() {
      return this.$store.state.configEmpresa.dados_usu;
    },
      mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },

  created() {
    this.listar_funcionarios();
    // this.dados_logado();
  },
  methods: {
    async autorizar(value) {
    
      await this.$store.dispatch("autorizar_send_biometria",value);
       this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
      

    },
  
    async listar_funcionarios() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("listar_funcionarios").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");


      });
     this.$store.dispatch("dados_logado");
    },
    atualizar(value) {
      this.$router.push({ name: "createFuncionario" });
      this.$store.dispatch("atualizar", value);
    },

    async toGo(rota, value) {
      await this.$store.dispatch("listar_registro_relogio_funcionario", value);

           this.$router.push({name: rota, params: {id_funcionario: value.id}});
    },
     alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then(() => {
    
      
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>